import styles from "@styles/components/sections/guarantee-section.module.scss";

export default function NewsLetterForm() {
  return (
    <form
      id="newsletterForm"
      className={styles.newsletterForm}
      action="//manage.kmail-lists.com/subscriptions/subscribe"
      data-ajax-submit="//manage.kmail-lists.com/ajax/subscriptions/subscribe"
      method="GET"
      target="_blank"
      data-newsletter-form
    >
      <input
        className={styles.formInput}
        type="hidden"
        name="g"
        value="NY9dxN"
      />

      <div
        className="text-center klaviyo_messages hide"
        data-newsletter-message
        data-success-message="Thank you! Check your email for your coupon code!"
      >
        <div className={styles.successMessage}>
          <strong>Your discount will be applied in checkout.</strong>
          {/* <br> */}
          <a href="/join-the-club">Click here to redeem your offer!</a>
        </div>
        <div className={styles.errorMessage}></div>
      </div>

      <div className="klaviyo_controls form-inline" data-newsletter-controls>
        <input
          className={`${styles.formInput} email-input`}
          type="email"
          placeholder="Your email address"
          name="email"
          autoCorrect="off"
          autoCapitalize="off"
          data-mobile-placeholder="Your email"
          data-desktop-placeholder="Your email address"
          aria-label="Newsletter signup email input"
          data-newsletter-input
          required
        />

        <div>
          <button
            type="submit"
            className={`${styles.guaranteeBtn} btn`}
            aria-label="Newsletter signup submit"
            data-newsletter-submit
          >
            Go
            <span className={`${styles.guaranteeIcon} icon-arrow-right`}></span>
          </button>
        </div>
      </div>
    </form>
  );
}
